var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},_vm._l((_vm.classes),function(course,index){return _c('div',{key:index,staticClass:"col-sm-4",staticStyle:{"padding":"1rem"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: _vm.routerName,
          params: { id: course.id }
        }}},[_c('el-card',{staticClass:"course-card",staticStyle:{"max-width":"350px","margin":"0 auto"},attrs:{"body-style":{ padding: '0px' },"shadow":"hover"}},[_c('div',{staticClass:"card-cover"},[_vm._v(" "+_vm._s(course.name.substr(0, 3))+" ")]),_c('div',{staticStyle:{"width":"100%","padding":"14px"}},[_c('h5',{staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(course.name)+" ")]),_c('div',{staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(course.description)+" ")]),_c('div',{staticClass:"bottom clearfix"},[_c('time',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$t("TeacherCourses.Number of students"))+": "+_vm._s(course.student_users_count)+" ")]),_c('router-link',{attrs:{"to":{
                  name: _vm.routerName,
                  params: { id: course.id }
                }}},[_c('el-button',{staticClass:"button",attrs:{"type":"text"}},[_vm._v(" "+_vm._s(_vm.$t("TeacherCourses.View Class"))+" ")])],1)],1)])])],1)],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }